import * as React from 'react';
import { SVGProps } from 'react';

const SvgInfoIconSecondary = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 1 1 0 16.666Zm0-1.666a6.666 6.666 0 1 0 0-13.333 6.666 6.666 0 0 0 0 13.333ZM9.167 5.833h1.666V7.5H9.167V5.833Zm0 3.334h1.666v5H9.167v-5Z" />
    </svg>
  );
};
export default SvgInfoIconSecondary;
